
ion-header ion-toolbar ion-searchbar {
    #--border-radius: 10px !important;
    #--color: #FFF !important;
}

ion-header {
    #--ion-background-color: #222;
    #--ion-color: #CCC;
}


ion-content {
    #--ion-background-color: #444;
    #--ion-color: #CCC;
}

ion-icon {
    font-size: 20px;
}



h3 {
    color: #FFF;
}

.spt_asset_thumbnail {
    width: 20%;
    height: auto;
    margin: 10px 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    box-sizing: border-box;
    padding: 0px;
}


