
.spt_asset_thumbnail {
    width: 20%;
    height: auto;
    margin: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
}


