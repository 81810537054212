
a {
    text-decoration: none;
}


.spt_form_panel {
    width: 100% !important;
}

.spt_textfield {
    padding: 0px !important;
    margin: 0px !important;
    font-size: 12px !important;
}


.spt_textarea {
    padding: 0px !important;
    margin: 0px !important;
    font-size: 12px !important;
}
